<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn class="ma-2" dark color="success">
                            <v-icon>mdi-plus</v-icon>
                            Add Sign
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                  <strong><v-icon x-large>mdi-sign-text</v-icon></strong> Signs
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    v-model="selected"
                    :search="search"
                    :headers="headers"
                    :items="desserts"
                    item-key="name"
                    show-select
                    class="elevation-1 table-one"
                    multi-sort
                >
                    <template v-slot:item.name="{item}">
                        <div class="d-flex align-center">
                            <p class="ma-0 font-weight-medium">
                                {{ item.name }}
                            </p>
                        </div>
                    </template>
                  <template v-slot:item.occupancy="{item}">
                    <div class="d-flex">

                        <template>
                        <v-progress-linear
                            :value="item.occupancy"
                            width="50"
                            height="10"
                            striped
                            color="deep-orange"
                        />
                        </template>
                    </div>
                  </template>
                    <template v-slot:item.action="{item}">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.badge="{item}">
                        <template v-if="item.badge === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip
                                class=""
                                color="danger"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                    </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    metaInfo: {
        title: 'Sign'
    },
    data() {
        return {
            search: '',
            selected: [],
            headers: [
              {
                text: 'Name',
                sortable: true,
                value: 'name',
                align: 'left',
              },

              {
                text: 'Actions',
                value: 'action',
              }
            ],
            desserts: [
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    name: 'A/B Lower',
                    capacity: 60,
                    occupancy: 90
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'A/B Upper',
                    capacity: 70,
                    occupancy: 50
                },
              {
                img: require('@/assets/images/avatars/002-woman.svg'),
                name: 'Multi Storey',
                capacity: 40,
                occupancy: 80
              },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
